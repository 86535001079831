import { FC, useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Header from "../../components/Header/Header";
import PanelMaster from "../../components/PanelMaster/PanelMaster";
import {
  faUserGroup,
  faUserXmark,
  faUserCheck,
  faUser,
  faDownLeftAndUpRightToCenter,
  faArrowDown,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import CardStatusDashboard from "../../components/CardStatusDashboard/CardStatusDashboard";
import "./Dashboard.scss";
import GaugeChart from "react-gauge-chart";
import ChartPerformance from "../../components/ChartPerformance/ChartPerformance";
import DashboardService from "../../services/DashboardService";
import Loading from "../../components/Loading/Loading";
import DashboardResourceScore from "../../components/DashboardResourceScore/DashboardResourceScore";
import { toastSettings } from "../../helpers/toastSettings";
import ModalUserWorseResource from "../../components/ModalUserWorseResource/ModalUserWorseResource";

type DashboardProps = {};

const Dashboard: FC<DashboardProps> = () => {
  const [chartPerformanceData, setChartPerformanceData] = useState<any | null>(
    null
  );
  const [dateFilter, setDateFilter] = useState<[string, string] | null>(null);
  const [modalUserWorseResouce, setModalUserWorseResouce] =
    useState<boolean>(false);
  const [selectedResource, setSelectedResource] = useState<string>("");

  const [isChartPerformanceLoading, setChartPerformanceLoadingLoading] =
    useState<boolean>(false);

  const getData = async () => {
    setChartPerformanceLoadingLoading(true);
    const _Response = await DashboardService.index(dateFilter);
    setChartPerformanceData(_Response);
    setChartPerformanceLoadingLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const filterData = () => {
    if (isChartPerformanceLoading) return;

    if (dateFilter?.[0] && dateFilter?.[1]) {
      getData();
    } else {
      toastSettings("Informe as duas datas para realizar a filtragem", "error");
    }
  };

  return (
    <>
      <div className="Dashboard">
        <Header />
        <PanelMaster title="Dashboard">
          <Container>
            <Row>
              <Col>
                <div className="d-flex">
                  <Col md={2} className="mr-3">
                    <input
                      type="date"
                      className="search w-100"
                      onChange={(e) =>
                        setDateFilter([
                          e.target.value,
                          dateFilter ? dateFilter[1] : "",
                        ])
                      }
                    />
                  </Col>
                  <Col md={2} className="">
                    <input
                      type="date"
                      className="search w-100"
                      onChange={(e) =>
                        setDateFilter([
                          dateFilter ? dateFilter[0] : "",
                          e.target.value,
                        ])
                      }
                    />
                  </Col>
                  <Col>
                    <Button
                      variant="secondary"
                      className="mx-2 search"
                      onClick={() => {
                        filterData();
                      }}
                    >
                      FILTRAR
                    </Button>
                  </Col>
                </div>
              </Col>
            </Row>
            <Row className="gap-3 d-flex justify-content-center mt-3">
              <Col>
                <CardStatusDashboard
                  icon={faUserGroup}
                  title="Usuários Ativos"
                  content={chartPerformanceData?.[0]?.["usuariosAtivos"]}
                />
              </Col>
              <Col>
                <CardStatusDashboard
                  icon={faUserXmark}
                  title="Usuários Inativos"
                  content={chartPerformanceData?.[0]?.["usuariosInativos"]}
                />
              </Col>
              <Col>
                <CardStatusDashboard
                  icon={faCheck}
                  title="Jornadas Concluídas"
                  content={chartPerformanceData?.[0]?.["qtdJornadasConcluidas"]}
                />
              </Col>
            </Row>
            <Row className="gap-3 d-flex justify-content-center mt-3">
              {chartPerformanceData?.[0]?.["qtdPioresRecursos"] &&
                Object.entries(
                  chartPerformanceData?.[0]["qtdPioresRecursos"]
                )?.map((recurso: any) => {
                  return (
                    <Col
                      role={"button"}
                      onClick={() => {
                        setSelectedResource(recurso?.[0]);
                        setModalUserWorseResouce(true);
                      }}
                    >
                      <CardStatusDashboard
                        icon={faArrowDown}
                        title={recurso?.[0]}
                        content={recurso?.[1]}
                      />
                    </Col>
                  );
                })}
            </Row>
            <Row className="mt-3">
              <Card>
                <Card.Title className="m-3">
                  A média das notas de cada recurso
                </Card.Title>
                <Card.Body>
                  {isChartPerformanceLoading ? (
                    <Loading />
                  ) : (
                    <ChartPerformance subResourcesData={chartPerformanceData} />
                  )}
                </Card.Body>
              </Card>
            </Row>
            {/* <Row>
            <GaugeChart
            nrOfLevels={3}
            colors={["#FF5F6D", "#fff361", "#64ff61"]}
            arcWidth={0.2}
            percent={10 / 100}
            hideText
            />
          </Row> */}
            <Row className="mt-3">
              {chartPerformanceData &&
                chartPerformanceData?.[0]?.["dadosRecursos"]?.map(
                  (data: any, key: number) => {
                    return (
                      <DashboardResourceScore
                        key={key}
                        data={{
                          iconeRecurso: data.icone,
                          descricaoRecurso: data.titulo,
                          maiorNota: data?.maiorNota,
                          recursoCorFundo: data.corFundo,
                          subRecursos: data.subRecursos,
                        }}
                      />
                    );
                  }
                )}
            </Row>
          </Container>
        </PanelMaster>
      </div>
      <ModalUserWorseResource
        show={modalUserWorseResouce}
        onClose={setModalUserWorseResouce}
        resource={selectedResource}
      />
    </>
  );
};

export default Dashboard;
