import * as yup from "yup";

const ValidationEdit = yup.object().shape({
  email: yup
    .string()
    .email("O campo precisa ser do tipo e-mail")
    .required("O campo e-mail é obrigatório"),
  username: yup.string().required("O campo nome é obrigatório"),
  clientId: yup.number().required("O campo cliente é obrigatório"),
});

export default ValidationEdit;
