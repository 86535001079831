import { FC } from "react";
import { Container } from "react-bootstrap";
import "./PanelMaster.scss";
import React from "react";

type PanelMasterProps = {
  title: string;
  children: React.ReactNode | React.ReactNode[];
};

const PanelMaster: FC<PanelMasterProps> = ({ title, children }) => {
  return (
    <div className="PanelMaster">
      <Container className="w-100">
        <div className="d-flex justify-content-between my-3">
          <div className="title">{title}</div>
          {/* <div>A</div> */}
        </div>
      </Container>
      {children}
    </div>
  );
};

export default PanelMaster;
