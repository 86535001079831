import { FC, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ClientsTable from "../../components/ClientsTable/ClientsTable";
import Header from "../../components/Header/Header";
import PanelMaster from "../../components/PanelMaster/PanelMaster";
import "./Clients.scss";

type ClientsProps = {};

const Clients: FC<ClientsProps> = () => {
  const [filteredText, setFilteredText] = useState<string>("");
  const [showModalAddManager, setShowModalAddManager] =
    useState<boolean>(false);

  return (
    <div className="Clients">
      <Header />
      <PanelMaster title="Clientes">
        <Container>
          <Row className="m-0 p-0">
            <Col xs={12} md={12}>
              <div className="d-flex justify-content-between">
                <Col xs={9} md={4}>
                  <input
                    type="text"
                    className="search w-100"
                    placeholder="Procurar por nome"
                    onChange={(e) => setFilteredText(e.target.value)}
                  />
                </Col>
              </div>
            </Col>
          </Row>
          <div className="clearfix mt-5"></div>
          <ClientsTable filter={filteredText} />
        </Container>
      </PanelMaster>
    </div>
  );
};

export default Clients;
