import Api from "./Api";

const TranscriptionService = {
  async transcript(data: object) {
    try {
      const response = await Api.post(`/transcricao`, data);

      return response.data;
    } catch (e: any) {
      return null;
    }
  },
  async getGroupsAndWords() {
    try {
      const response = await Api.get(`/transcricao/grupo`);

      return response.data;
    } catch (e: any) {
      return null;
    }
  },
  async addGroup(data: any) {
    try {
      const response = await Api.post(`/transcricao/grupo`, data);

      return response.data;
    } catch (e: any) {
      return null;
    }
  },
  async addWord(data: any) {
    try {
      const response = await Api.post(`/transcricao/palavra`, data);

      return response.data;
    } catch (e: any) {
      return null;
    }
  },
  async deleteGroup(idGroup: Number) {
    try {
      const response = await Api.delete(`/transcricao/grupo/${idGroup}`);

      return response.data;
    } catch (e: any) {
      return null;
    }
  },
  async deleteWord(idWord: Number) {
    try {
      const response = await Api.delete(`/transcricao/palavra/${idWord}`);

      return response.data;
    } catch (e: any) {
      return null;
    }
  },
};

export default TranscriptionService;
