import { Button, Col, Container, Form, Row } from "react-bootstrap";
import LoginSVG1 from "./../../assets/images/login-1-image.svg";
import LoginSVG2 from "./../../assets/images/login-2-image.svg";
import LoginSVG3 from "./../../assets/images/login-3-image.svg";
import SVG from "react-inlinesvg";
import "./Login.scss";
import { useForm } from "react-hook-form";
import Validation from "./Validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Loading from "../../components/Loading/Loading";

type FormValues = {
  email: string;
  password: string;
};

export default function Login() {
  const [isLoading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(Validation),
  });

  const { login } = useAuth();
  const navigate = useNavigate();

  const handle = handleSubmit(
    async (data: { email: string; password: string }) => {
      setLoading(true);
      if (await login(data)) navigate("/");
      setLoading(false);
    }
  );

  return (
    <div className="Login">
      <Container>
        <Row>
          <SVG src={LoginSVG3} />
        </Row>
        <Row>
          <Col md={4} sm={12} className="column text-center d-none d-lg-block">
            <SVG src={LoginSVG1} />
          </Col>
          <Col xs={12} sm={12} lg={4} className="column">
            <h1 className="mt-5">Login do Administrador</h1>
            <p className="mt-3">
              Preencha as credenciais de acesso abaixo para efetuar o login no
              painel.
            </p>

            <Form className="mt-5" onSubmit={handle}>
              <Form.Group>
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  placeholder="administrador@uelf.com.br"
                  type="email"
                  {...register("email")}
                />
                <p className="text-danger">{errors.email?.message}</p>
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label>Senha</Form.Label>
                <Form.Control
                  placeholder="**********"
                  type="password"
                  {...register("password")}
                />
                <p className="text-danger">{errors.password?.message}</p>
              </Form.Group>
              {isLoading && <Loading />}
              <Button
                disabled={isLoading ?? false}
                variant="primary"
                type="submit"
                className="mt-5"
              >
                ENTRAR
              </Button>
            </Form>
          </Col>
          <Col md={4} className="column text-center d-none d-lg-block">
            <SVG src={LoginSVG2} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
