import { FC } from "react";
import { Routes, Route } from "react-router-dom";
import Clients from "../pages/Clients/Clients";
import Dashboard from "../pages/Dashboard/Dashboard";
import History from "../pages/History/History";
import Login from "../pages/Login/Login";
import Managers from "../pages/Managers/Managers";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import VideoTranscription from "../pages/VideoTranscription/VideoTranscription";
import { ClientProvider } from "../providers/ClientProvider";
import { ManagerProvider } from "../providers/ManagerProvider";
import { AuthenticationGate } from "./PrivateRoute";

const Router: FC<unknown> = () => {
  return (
    <ManagerProvider>
      <ClientProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<AuthenticationGate />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/history" element={<History />} />
            <Route path="/managers" element={<Managers />} />
            <Route path="/clients" element={<Clients />} />
            <Route
              path="/curadoria"
              element={<VideoTranscription />}
            />
          </Route>
        </Routes>
      </ClientProvider>
    </ManagerProvider>
  );
};

export default Router;
