import Api from "./Api";

const PlansService = {
  async listAll() {
    try {
      const response = await Api.get(`/pessoa`);

      return response.data;
    } catch (e: any) {
      return null;
    }
  },
};

export default PlansService;
