import { FC, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { Modal } from "reactstrap";
import Loading from "../Loading/Loading";
import DashboardService from "../../services/DashboardService";

class Data {
  nomeRazaoSocial: string;
  login: string;

  constructor({ nomeRazaoSocial, login }: any) {
    this.nomeRazaoSocial = nomeRazaoSocial || "";
    this.login = login || "";
  }
}

const ModalUserWorseResource: FC<any> = ({ show, onClose, resource }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [data, setData] = useState<Data[]>([]);

  const toggle = () => setModal(!modal);
  const handleOnClose = () => onClose(false);

  useEffect(() => {
    if (show) {
      setModal(show);

      if (!isLoading) {
        getData();
      }
    }
  }, [show]);

  const getData = async () => {
    setLoading(true);
    setData([]);
    const data = (await DashboardService.listUserWorseResource(resource))[0];
    setData(data);
    setLoading(false);
  };

  const columns = [
    {
      name: "Nome",
      selector: (row: Data) => row.nomeRazaoSocial,
    },
    {
      name: "E-mail",
      selector: (row: Data) => row.login,
    },
  ];

  return (
    <>
      <Modal
        centered
        scrollable
        size="xl"
        isOpen={modal}
        toggle={toggle}
        onClosed={handleOnClose}
        className="ModalUserWorseResources"
      >
        <ModalHeader>Usuários com o pior recurso {resource}</ModalHeader>
        <ModalBody>
          {isLoading && <Loading />}
          {data.length > 0 && (
            <DataTable
              responsive
              highlightOnHover
              pagination
              columns={columns}
              data={data}
            />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default ModalUserWorseResource;
