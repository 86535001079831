import { toast, ToastPosition, TypeOptions } from "react-toastify";

export const toastSettings = (
  message: string,
  type: TypeOptions = "warning",
  position: ToastPosition = "bottom-right",
  onClose?: () => void
) => {
  toast(message, {
    position: position,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type,
    // theme: "white",
    onClose: () => onClose,
  });
};
