import { FC, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { Modal } from "reactstrap";
import Loading from "../Loading/Loading";
import Api from "../../services/Api";
import ModalTranscriptionData from "../ModalTranscriptionData/ModalTranscriptionData";

class Transcription {
  fonte: string;
  fonteDados: number;
  dados: any;
  dataCriacao: any;

  constructor({ fonte, fonteDados, dados, dataCriacao }: any) {
    this.fonte = fonte.slice(-11) || "";
    this.fonteDados = fonteDados || "";
    this.dados = dados || "";
    this.dataCriacao = dataCriacao || null;
  }
}

enum DataSource {
  YOUTUBE = 1,
}

const ModalLastTranscription: FC<any> = ({ show, onClose }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [modalTranscriptionData, setModalTranscriptionData] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [dataTranscription, setDataTranscription] = useState<any>([]);
  const [transcriptions, setTranscriptions] = useState<Transcription[] | null>(
    null
  );

  const toggle = () => setModal(!modal);
  const handleOnClose = () => onClose(false);

  useEffect(() => {
    if (show) {
      setModal(show);
    }

    if (!isLoading && !transcriptions) {
      setLoading(true);
      Api.get(`/transcricao`).then((data: any) => {
        console.log();
        setTranscriptions(data.data);
        setLoading(false);
      });
    }
  }, [show, isLoading]);

  const columns = [
    {
      name: "Fonte",
      cell: (row: Transcription) => (
       <a role={"button"} onClick={() => {
        setDataTranscription(JSON.parse(row.dados)?.transcricoes);
        setModalTranscriptionData(true)
       }}>{row.fonte.slice(-11)}</a>
      ),
    },
    {
      name: "Fonte de Dados",
      selector: (row: Transcription) => DataSource[row.fonteDados],
    },
    {
      name: "Categoria",
      selector: (row: Transcription) => JSON.parse(row.dados)?.categoria,
    },
    {
      name: "Data Criação",
      selector: (row: Transcription) =>
        Intl.DateTimeFormat("pt-Br", {
          dateStyle: "short",
          timeStyle: "medium",
        }).format(new Date(row.dataCriacao)),
    },
  ];

  return (
    <>
      <Modal
        centered
        scrollable
        size="xl"
        isOpen={modal}
        toggle={toggle}
        onClosed={handleOnClose}
        className="ModalLastTranscriptions"
      >
        <ModalHeader>Últimas Transcrições</ModalHeader>
        <ModalBody>
          {isLoading && <Loading />}
          {!isLoading && transcriptions?.length && (
            <DataTable
              responsive
              highlightOnHover
              pagination
              columns={columns}
              data={transcriptions}
              keyField="uniqueId"
            />
          )}
        </ModalBody>
      </Modal>
      <ModalTranscriptionData data={dataTranscription} show={modalTranscriptionData} onClose={setModalTranscriptionData} /> 
    </>
  );
};

export default ModalLastTranscription;
