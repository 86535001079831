import React, { useState } from "react";
import { Manager } from "../models/Manager";
import ManagersService from "../services/ManagersService";

export const ManagerContext = React.createContext<Manager | any>({});

export const ManagerProvider = (props: any) => {
  const [managers, setManagers] = useState<Manager[] | null>(null);
  const [selectedManager, setSelectedManager] = useState<Manager>();

  const getManagers = async () => {
    const [_Response, _Error] = await ManagersService.listAll();

    if (!!_Error || !_Response?.status) {
      setManagers(_Response);
    }
  };

  const deleteManager = async () => {
    if (selectedManager) {
      const deleteManager = await ManagersService.delete({
        id: selectedManager.id,
        clientId: selectedManager.idCliente,
      });

      setManagers(null);

      return deleteManager;
    }

    return false;
  };

  const updateManager = async (data: any) => {
    if (selectedManager) {
      const updateManager = await ManagersService.update({
        id: selectedManager.id,
        username: data.username,
        email: data.email,
        password: data.password,
        clientId: selectedManager.idCliente,
      });

      setManagers(null);

      return updateManager;
    }

    return false;
  };

  return (
    <ManagerContext.Provider
      value={{
        managers,
        getManagers,
        setManagers,
        selectedManager,
        setSelectedManager,
        deleteManager,
        updateManager,
      }}
    >
      {props.children}
    </ManagerContext.Provider>
  );
};

export const useManager = () => React.useContext(ManagerContext);
