import Cookies from "js-cookie";
import Api from "./Api";

const DashboardService = {
  async pessoa() {
    try {
      const response = await Api.post(`/pessoa`);

      return [response.data, undefined];
    } catch (e: any) {
      return [
        undefined,
        e?.response?.data?.message ||
          "Os dados informados não conferem, por favor verique os dados e tente novamente.",
      ];
    }
  },
  async index(filtroData?: [string, string] | null) {
    try {
      var response = filtroData?.length
        ? await Api.get(`/dashboard/${filtroData?.join("/")}`)
        : await Api.get(`/dashboard`);

      return [response.data, undefined];
    } catch (e: any) {
      return [
        undefined,
        e?.response?.data?.message ||
          "Os dados informados não conferem, por favor verique os dados e tente novamente.",
      ];
    }
  },
  async listAllOccupations() {
    try {
      var response = await Api.get(`/ocupacao`);

      return [response.data, undefined];
    } catch (e: any) {
      return [
        undefined,
        e?.response?.data?.message ||
          "Os dados informados não conferem, por favor verique os dados e tente novamente.",
      ];
    }
  },
  async listAllResources() {
    try {
      var response = await Api.get(`/recurso`);

      return [response.data, undefined];
    } catch (e: any) {
      return [
        undefined,
        e?.response?.data?.message ||
          "Os dados informados não conferem, por favor verique os dados e tente novamente.",
      ];
    }
  },
  async listUserWorseResource(resource: string) {
    try {
      var response = await Api.get(`/dashboard/piorrecurso/${resource}`);

      return [response.data, undefined];
    } catch (e: any) {
      return [
        undefined,
        e?.response?.data?.message ||
          "Os dados informados não conferem, por favor verique os dados e tente novamente.",
      ];
    }
  },
};

export default DashboardService;
