import { useEffect, useState } from "react";
import {
  Button,
  Col,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "react-bootstrap";
import { Modal } from "reactstrap";
import { toastSettings } from "../../helpers/toastSettings";
import { useManager } from "../../providers/ManagerProvider";
import Loading from "../Loading/Loading";

interface ModalConfirmationProps {
  show: boolean;
  onClose?: any;
  message: string;
  onConfirm: any;
  variantButtonConfirm?: string;
}

const ModalConfirmation = ({
  show,
  onClose,
  message,
  onConfirm,
  variantButtonConfirm = "danger",
}: ModalConfirmationProps) => {
  const [modal, setModal] = useState<boolean>(false);
  const { selectedManager } = useManager();
  const [isLoading, setLoading] = useState<boolean>(false);

  const toggle = () => setModal(!modal);
  const handleOnClose = () => onClose(false);

  useEffect(() => {
    if (show) {
      setModal(show);
    }
  }, [show]);

  const handleConfirm = async () => {
    setLoading(true);
    const data = await onConfirm();
    setLoading(false);
    toggle();

    if (data?.message) return toastSettings(data?.message, "success");

    return false;
  };

  return (
    <Modal
      centered
      scrollable
      size="md"
      isOpen={modal}
      toggle={toggle}
      onClosed={handleOnClose}
      className="ModalAddManager"
    >
      <ModalHeader>Confirmar</ModalHeader>
      <ModalBody>
        <Row>
          <p className="text-center">
            {selectedManager?.nome}
            {"  "}
            <span className="border rounded border-3">
              {selectedManager?.cliente}
            </span>
          </p>
          <p className="text-center">{message}</p>
          {isLoading && <Loading />}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Row className="gap-3">
          <Col>
            <Button
              disabled={isLoading}
              variant={variantButtonConfirm}
              onClick={handleConfirm}
            >
              CONFIRMAR
            </Button>
          </Col>
          <Col>
            <Button variant="secondary" onClick={toggle}>
              CANCELAR
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default ModalConfirmation;
