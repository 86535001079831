import { FC } from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import "./CardStatusDashboard.scss";

type CardStatusDashboardProps = {
  icon: IconDefinition;
  title: string;
  content: string;
};

const CardStatusDashboard: FC<CardStatusDashboardProps> = ({
  icon,
  title,
  content,
}) => {
  return (
    <div className="CardStatusDashboard">
      <Card>
        <Card.Title>
          <FontAwesomeIcon icon={icon} /> {title}
        </Card.Title>
        <Card.Body className="d-flex justify-content-center w-100">
          {content}
        </Card.Body>
      </Card>
    </div>
  );
};

export default CardStatusDashboard;
