import React, { FC, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";

interface Props {
  element?: any;
  path?: string;
}

export const AuthenticationGate: FC<Props> = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    const disposer = () => setTimeout(() => setIsLoading(false), 0);
    disposer();
  });

  if (isLoading) {
    return <></>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <Outlet />
    </div>
  );
};
