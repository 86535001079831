import { FC, useEffect, useState } from "react";
import "./ClientsTable.scss";
import DataTable from "react-data-table-component";
import Loading from "../Loading/Loading";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { useManager } from "../../providers/ManagerProvider";
import { Manager } from "../../models/Manager";
import { useClient } from "../../providers/ClientProvider";
import { Client } from "../../models/Client";
import moment from "moment";
import Status from "../../enums/Status";
import ModalEditClient from "../ModalEditClient/ModalEditClient";
import Plans from "../../enums/Plans";

const ClientsTable: FC<any> = ({ filter = "" }) => {
  const { clients, getAllClients, setSelectedClient } = useClient();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [modalEditManager, setModalEditManager] = useState<boolean>(false);

  useEffect(() => {
    if (!clients?.length) {
      setLoading(true);
      getAllClients();
    } else {
      setLoading(false);
    }
  }, [clients]);

  const columns = [
    {
      name: "Nome",
      selector: (row: Client) => row.nome,
    },
    {
      name: "Status",
      selector: (row: Client) => Status[row.ativo].toUpperCase(),
      conditionalCellStyles: [
        {
          when: (row: Client) => row.ativo == 1,
          style: {
            color: "#99DA35",
            fontWeight: "bold",
          },
        },
        {
          when: (row: Client) => row.ativo == 0,
          style: {
            color: "#ff0000",
            fontWeight: "bold",
          },
        },
      ],
    },
    {
      name: "Plano",
      selector: (row: Client) => Plans[row.plano].toUpperCase(),
    },
    {
      name: "Banco de Dados",
      selector: (row: Client) => row.dbNome,
    },
    {
      name: "Data Cadastro",
      selector: (row: Client) => moment(row.dtCadastro).format("DD/MM/YYYY"),
    },
    {
      name: "Ações",
      cell: (client: Client) => (
        <div className="d-flex gap-1">
          <div>
            <Button
              variant="light"
              title="EDITAR"
              onClick={() => {
                setSelectedClient(client);
                setModalEditManager(true);
              }}
            >
              <FontAwesomeIcon icon={faUserEdit} />
            </Button>
          </div>
        </div>
      ),
    },
  ];

  const filteredItems = clients?.filter((item: Client) => {
    let filtered =
      JSON.stringify(item).toLowerCase().indexOf(filter.toLowerCase()) !== -1;

    return filtered;
  });

  return (
    <>
      <div className="ClientsTable">
        {isLoading && <Loading />}

        {clients && (
          <DataTable
            responsive
            highlightOnHover
            pagination
            columns={columns}
            data={filteredItems ? filteredItems : []}
            keyField="uniqueId"
          />
        )}
      </div>
      <ModalEditClient show={modalEditManager} onClose={setModalEditManager} />
    </>
  );
};

export default ClientsTable;
