import { FC, useEffect, useState } from "react";
import "./ManagersTable.scss";
import DataTable from "react-data-table-component";
import Loading from "../Loading/Loading";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import ModalConfirmation from "../ModalConfirmation/ModalConfirmation";
import { useManager } from "../../providers/ManagerProvider";
import { Manager } from "../../models/Manager";
import ModalAddManager from "../ModalAddManager/ModalAddManager";

const ManagersTable: FC<any> = ({ filter = "" }) => {
  const { managers, getManagers, setSelectedManager, deleteManager } =
    useManager();
  const [isLoading, setLoading] = useState<boolean>(false);

  const [modalConfirmation, setModalConfirmation] = useState<boolean>(false);
  const [modalEditManager, setModalEditManager] = useState<boolean>(false);
  const [modalConfirmationMessage, setModalConfirmationMessage] =
    useState<string>("");

  useEffect(() => {
    if (!managers?.length) {
      setLoading(true);
      getManagers();
    } else {
      setLoading(false);
    }
  }, [managers]);

  const columns = [
    {
      name: "Usuário",
      selector: (row: Manager) => row.nome,
    },
    {
      name: "E-mail",
      selector: (row: Manager) => row.login,
    },
    {
      name: "Cliente",
      selector: (row: Manager) => row.cliente,
    },
    {
      name: "Ações",
      cell: (manager: Manager) => (
        <div className="d-flex gap-1">
          <div>
            <Button
              variant="light"
              title="EDITAR"
              onClick={() => {
                setSelectedManager(manager);
                setModalEditManager(true);
              }}
            >
              <FontAwesomeIcon icon={faUserEdit} />
            </Button>
          </div>
          <div>
            <Button
              variant="danger"
              title="EXCLUIR"
              onClick={() => {
                setSelectedManager(manager);
                setModalConfirmationMessage(
                  "Você confirma a exclusão deste gestor?"
                );
                setModalConfirmation(true);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </div>
        </div>
      ),
    },
  ];

  const filteredItems = managers?.filter((item: Manager) => {
    let filtered =
      JSON.stringify(item).toLowerCase().indexOf(filter.toLowerCase()) !== -1;

    return filtered;
  });

  return (
    <>
      <div className="ManagersTable">
        {isLoading && <Loading />}

        {managers && (
          <DataTable
            responsive
            highlightOnHover
            pagination
            columns={columns}
            data={filteredItems ? filteredItems : []}
            keyField="uniqueId"
          />
        )}
      </div>
      <ModalConfirmation
        show={modalConfirmation}
        onClose={setModalConfirmation}
        message={modalConfirmationMessage}
        onConfirm={deleteManager}
      />
      <ModalAddManager
        show={modalEditManager}
        onClose={setModalEditManager}
        edit
      />
    </>
  );
};

export default ManagersTable;
