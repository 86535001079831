import Api from "./Api";

const ClientsService = {
  async listActive() {
    try {
      const response = await Api.get(`/cliente/ativo`);

      return [response.data, undefined];
    } catch (e: any) {
      return [
        undefined,
        e?.response?.data?.message ||
          "Os dados informados não conferem, por favor verique os dados e tente novamente.",
      ];
    }
  },
  async listAll() {
    try {
      const response = await Api.get(`/cliente`);

      return [response.data, undefined];
    } catch (e: any) {
      return [
        undefined,
        e?.response?.data?.message ||
          "Os dados informados não conferem, por favor verique os dados e tente novamente.",
      ];
    }
  },
  async update(data: { id: number; active: boolean; plan: boolean; waitingTime: number; licencesEvolution: number; licencesEducation: number; licencesFull: number }) {
    try {
      const response = await Api.put(`/cliente/${data.id}`, {
        ativo: data.active,
        plano: data.plan,
        tempoEsperaJornada: data.waitingTime,
        licencasEvolution: data.licencesEvolution,
        licencasEducation: data.licencesEducation,
        licencasFull: data.licencesFull
      });

      return response.data;
    } catch (e: any) {
      return false;
    }
  },
};

export default ClientsService;
