import Api from "./Api";

const ManagersService = {
  async listAll() {
    try {
      const response = await Api.get(`/gestor`);

      return [response.data, undefined];
    } catch (e: any) {
      return [
        undefined,
        e?.response?.data?.message ||
          "Os dados informados não conferem, por favor verique os dados e tente novamente.",
      ];
    }
  },

  async insert(data: {
    email: string;
    password: string;
    username: string;
    clientId: number;
  }) {
    try {
      const response = await Api.post(`/gestor`, {
        login: data.email,
        senha: data.password,
        nome: data.username,
        idCliente: data.clientId,
      });

      return response.data;
    } catch (e: any) {
      return false;
    }
  },

  async delete(data: { id: number; clientId: number }) {
    try {
      const response = await Api.delete(`/gestor/${data.clientId}/${data.id}`);

      return response.data;
    } catch (e: any) {
      return false;
    }
  },
  async update(data: {
    id: number;
    email: string;
    password: string | null;
    username: string;
    clientId: number;
  }) {
    try {
      const response = await Api.put(`/gestor/${data.clientId}/${data.id}`, {
        login: data.email,
        senha: data.password,
        nome: data.username,
      });

      return response.data;
    } catch (e: any) {
      return false;
    }
  },
};

export default ManagersService;
