import { FC } from "react";
import { Spinner } from "react-bootstrap";
import "./Loading.scss";

type LoadingProps = {
  width?: string;
  height?: string;
};

const Loading: FC<LoadingProps> = ({ width = "3rem", height = "3rem" }) => {
  return (
    <div className="Loading w-100 d-flex justify-content-center p-3">
      <Spinner
        title="Carregando"
        role={"status"}
        animation="border"
        style={{ width: width, height: height }}
      />
    </div>
  );
};

export default Loading;
