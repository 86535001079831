import Cookies from "js-cookie";
import Api from "./Api";

const AuthService = {
  async login(data: { email: string; password: string }) {
    try {
      const response = await Api.post(`auth/login`, {
        email: data.email,
        password: data.password,
      });

      return response.data;
    } catch (e: any) {
      return null;
    }
  },

  logout() {
    localStorage?.clear();
    Cookies.remove("api_token");
    window.location.href = `${process.env.PUBLIC_URL}/login`;

    return true;
  },
};

export default AuthService;
