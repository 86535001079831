import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import "./ModalAddManager.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import ValidationAdd from "./ValidationAdd";
import { Form } from "react-bootstrap";
import Loading from "../Loading/Loading";
import ManagersService from "../../services/ManagersService";
import { toastSettings } from "../../helpers/toastSettings";
import { useClient } from "../../providers/ClientProvider";
import { useManager } from "../../providers/ManagerProvider";
import ValidationEdit from "./ValidationEdit";

interface ModalAddManagerProps {
  show: boolean;
  onClose?: any;
  edit?: boolean;
}

type FormValues = {
  email: string;
  password: string;
  username: string;
  clientId: number;
};

const ModalAddManager = ({
  show,
  onClose,
  edit = false,
}: ModalAddManagerProps) => {
  const [modal, setModal] = useState<boolean>(false);
  const { selectedManager, setManagers, updateManager } = useManager();
  const { clients, getActiveClients } = useClient();

  useEffect(() => {
    if (show) {
      setModal(show);

      if (edit) {
        setValue("email", selectedManager?.login);
        setValue("clientId", selectedManager?.idCliente);
        setValue("username", selectedManager?.nome);
      }
    }
  }, [show]);

  const toggle = () => setModal(!modal);
  const handleOnClose = () => onClose(false);

  const [isLoading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(edit ? ValidationEdit : ValidationAdd),
  });

  const handle = handleSubmit(
    async (data: {
      email: string;
      password: string;
      username: string;
      clientId: number;
    }) => {
      if (!edit) {
        setLoading(true);
        const response = await ManagersService.insert(data);
        setLoading(false);

        if (response) {
          toggle();
          reset();
          setManagers(null);
          return toastSettings(response.message, "success");
        }
      } else {
        setLoading(true);
        const response = await updateManager(data);
        setLoading(false);

        if (response) {
          toggle();
          reset();
          setManagers(null);
          return toastSettings(response.message, "success");
        }
      }
    }
  );

  useEffect(() => {
    if (!clients?.length) {
      getActiveClients();
    }
  }, [clients]);

  return (
    <Modal
      centered
      scrollable
      size="md"
      isOpen={modal}
      toggle={toggle}
      onClosed={handleOnClose}
      className="ModalAddManager"
    >
      <ModalHeader>{edit ? "Editar" : "Adicionar"} Gestor</ModalHeader>
      <ModalBody>
        <Row>
          <Col className="p-0 m-0">
            <Form className="p-3" onSubmit={handle}>
              <Form.Group>
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  placeholder="gestor@uelf.com.br"
                  type="email"
                  {...register("email")}
                />
                <p className="text-danger">{errors.email?.message}</p>
              </Form.Group>
              <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  placeholder="Lucas Silva"
                  type="text"
                  {...register("username")}
                />
                <p className="text-danger">{errors.username?.message}</p>
              </Form.Group>
              <Form.Group>
                <Form.Label>Senha</Form.Label>
                <Form.Control
                  placeholder="**********"
                  type="password"
                  {...register("password")}
                />
                <p className="text-danger">{errors.password?.message}</p>
              </Form.Group>
              <Form.Group>
                <Form.Label>Cliente</Form.Label>
                <Form.Select
                  defaultValue={"null"}
                  {...register("clientId")}
                  disabled={edit}
                >
                  <option disabled value={"null"}>
                    Selecione um cliente
                  </option>
                  {clients?.length &&
                    clients?.map((o: any, k: number) => {
                      return (
                        <option key={k} value={o.id}>
                          {o.nome}
                        </option>
                      );
                    })}
                </Form.Select>
                <p className="text-danger">{errors.clientId?.message}</p>
              </Form.Group>
              {isLoading && <Loading />}
              <button
                disabled={isLoading ?? false}
                type="submit"
                className="mt-3 btn btn-primary"
              >
                {edit ? "ATUALIZAR" : "CADASTRAR"}
              </button>
            </Form>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button type="button" onClick={toggle}>
          <span>Fechar</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalAddManager;
