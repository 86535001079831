import React, { useState } from "react";
import { Client } from "../models/Client";
import ClientsService from "../services/ClientsService";

export const ClientContext = React.createContext<Client | any>({});

export const ClientProvider = (props: any) => {
  const [clients, setClients] = useState<Client[] | null>(null);
  const [selectedClient, setSelectedClient] = useState<Client>();

  const getActiveClients = async () => {
    const [_Response, _Error] = await ClientsService.listActive();

    if (!!_Error || !_Response?.status) {
      setClients(_Response);
    }
  };

  const getAllClients = async () => {
    const [_Response, _Error] = await ClientsService.listAll();

    if (!!_Error || !_Response?.status) {
      setClients(_Response);
    }
  };

  const updateClient = async (data: any) => {
    if (selectedClient) {
      const updateClient = await ClientsService.update({
        id: selectedClient.id,
        active: data.active,
        plan: data.plan,
        waitingTime: data.waitingTime,
        licencesEvolution: data.licencesEvolution,
        licencesEducation: data.licencesEducation,
        licencesFull: data.licencesFull
      });

      setClients(null);

      return updateClient;
    }

    return false;
  };

  return (
    <ClientContext.Provider
      value={{
        clients,
        getActiveClients,
        getAllClients,
        selectedClient,
        setSelectedClient,
        updateClient,
      }}
    >
      {props.children}
    </ClientContext.Provider>
  );
};

export const useClient = () => React.useContext(ClientContext);
