import { useEffect, useState } from "react";
import {
  Button,
  Col,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "react-bootstrap";
import { Modal, Table } from "reactstrap";
import Loading from "../Loading/Loading";

interface ModalTranscriptionDataProps {
  data: any;
  show: boolean;
  onClose: any;
}

const ModalTranscriptionData = ({
  data,
  show,
  onClose,
}: ModalTranscriptionDataProps) => {
  const [modal, setModal] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const toggle = () => setModal(!modal);
  const handleOnClose = () => onClose(false);

  useEffect(() => {
    if (show) {
      setModal(show);
    }
  }, [show]);

  return (
    <Modal
      centered
      scrollable
      size="lg"
      isOpen={modal}
      toggle={toggle}
      onClosed={handleOnClose}
      className="ModalTranscriptionDataProps"
    >
      <ModalHeader>Resultado</ModalHeader>
      <ModalBody>
        <Row>
                      <Table responsive striped>
                        <thead>
                          <tr>
                            <th>Palavra</th>
                            <th>Localizadas</th>
                            <th>Total de Palavras</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map(
                            (transcricoes: any, ii: number) => (
                              <tr key={ii}>
                                <td>{transcricoes?.palavra}</td>
                                <td>{transcricoes?.ocorrencias}</td>
                                <td>{transcricoes?.palavras}</td>
                                <td>
                                  <progress
                                    value={
                                      (transcricoes?.ocorrencias /
                                      transcricoes?.palavras) * 100
                                    }
                                    max={100}
                                  >
                                    {(transcricoes?.ocorrencias /
                                      transcricoes?.palavras) * 100}
                                  </progress>
                                </td>
                              </tr>
                            )
                          )}
                          {
                            data?.length && (<tr>
                            <td></td>
                             <td>
                              {data?.map(
                                  (transcricoes: any) =>
                                    transcricoes?.ocorrencias
                                )
                                .reduce((a: number, b: number) => a + b)}
                            </td>
                            <td>{data[0]?.palavras}</td>
                            <td>
                              <progress
                                value={
                                  (data
                                    ?.map(
                                      (transcricoes: any) =>
                                        transcricoes?.ocorrencias
                                    )
                                    .reduce((a: number, b: number) => a + b) /
                                  data[0]?.palavras) * 100
                                }
                                max={100}
                              ></progress>
                            </td> 
                          </tr>)
                          }
                        </tbody>
                      </Table>
                    
          {isLoading && <Loading />}
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ModalTranscriptionData;
