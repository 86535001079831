import { yupResolver } from "@hookform/resolvers/yup";
import { FC, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { Multiselect } from "multiselect-react-dropdown";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import PanelMaster from "../../components/PanelMaster/PanelMaster";
import TranscriptionService from "../../services/TranscriptionService";
import Validation from "./Validation";
import "./VideoTranscription.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faLayerGroup,
  faList,
  faPlus,
  faPlusCircle,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { toastSettings } from "../../helpers/toastSettings";
import ModalLastTranscription from "../../components/ModalLastTranscriptions/ModalLastTranscriptions";
import ModalWorldGroup from "../../components/ModalWorldGroup/ModalWorldGroup";

type VideoTranscriptionProps = {};

type FormValues = {
  dataSource: number;
  source: string;
  words: string[];
};

const VideoTranscription: FC<VideoTranscriptionProps> = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [wordAdd, setWordAdd] = useState<string>("");
  const [wordsToSearch, setWordsToSearch] = useState<string[]>([]);
  const [showModalLastTranscription, setShowModalLastTranscription] =
    useState<boolean>(false);
  const [showModalWordGroup, setShowModalWordGroup] = useState<boolean>(false);
  const [wordsGroups, setWordsGroups] = useState<any>(null);
  const [groupsColor, setGroupsColor] = useState<any>(null);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(Validation),
  });

  const removeFromWordsToSearch = (word: string) => {
    let words = [...wordsToSearch];
    const index = words.indexOf(word);
    if (index !== -1) {
      words.splice(index, 1);
      setWordsToSearch(words);
    }

    setWordsToSearch(words);
  };

  const addFromGroupToWordsToSearch = (word: string) => {
    setWordsToSearch([...wordsToSearch, word]);
  };

  useEffect(() => {
    if (!showModalWordGroup) handleGroupsAndWords();
  }, [showModalWordGroup]);

  useEffect(() => {
    let words: string[] = [];

    wordsToSearch.forEach((word: string) => {
      const index = Object.keys(wordsGroups).indexOf(word);

      words.push(word);

      if (index > -1) {
        Object.values(wordsGroups[word as keyof {}]).forEach((item: any) => {
          words.push(item);
        });
      }
    });

    setValue("words", words);

    if (!wordsGroups) {
      handleGroupsAndWords();
    }
  }, [wordsToSearch]);

  const handleGroupsAndWords = async () => {
    const data = await TranscriptionService.getGroupsAndWords();
    let dataObj: any = {};
    let dataObjColor: any = {};

    if (data.length) {
      data.forEach((grupo: any) => {
        dataObj[grupo?.descricao] = grupo?.palavra.map((palavra: any) => {
          return palavra?.descricao;
        });
        dataObjColor[grupo?.descricao] = grupo?.cor;
      });
    }

    setGroupsColor(dataObjColor);
    setWordsGroups(dataObj);
  };

  const handle = handleSubmit(async (formValues: FormValues) => {
    setLoading(true);
    const searchedData: any[] = [];
    const sources = formValues.source.split(",");
    setData([]);

    await Promise.all(
      sources.map(async (source: string) => {
        const apiData = await TranscriptionService.transcript({
          fonteDados: formValues.dataSource,
          fonte: source,
          palavras: formValues.words,
        });

        if (apiData?.categoria != undefined) {
          searchedData.push({
            transcriptionData: apiData?.transcricoes,
            category: apiData?.categoria,
            source: source.slice(-11),
          });
        }
      })
    );

    setData(searchedData);
    setLoading(false);
  });

  return (
    <div className="VideoTranscription">
      <Header />
      <PanelMaster title="Curadoria">
        <Container>
          <div className="d-flex gap-2 mb-2">
            <Button
              title="Últimas Transcrições"
              onClick={() => {
                setShowModalLastTranscription(true);
              }}
            >
              <FontAwesomeIcon icon={faList} />
            </Button>
            <Button
              title="Grupo de Palavras"
              onClick={() => {
                setShowModalWordGroup(true);
              }}
            >
              <FontAwesomeIcon icon={faLayerGroup} />
            </Button>
          </div>
          <Row className="gap-5">
            <Col>
              <Form onSubmit={handle}>
                <Form.Group>
                  <Form.Label>Fonte de Dados</Form.Label>
                  <Form.Select
                    defaultValue={"null"}
                    {...register("dataSource")}
                  >
                    <option key={1} value={1}>
                      Youtube
                    </option>
                  </Form.Select>
                  <p className="text-danger">{errors.dataSource?.message}</p>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Fonte (Separar por vírgula)</Form.Label>
                  <Form.Control
                    defaultValue={""}
                    {...register("source")}
                  ></Form.Control>
                  <p className="text-danger">{errors.source?.message}</p>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Palavra(s)</Form.Label>
                  <div className="mt-2 gap-1 container-words">
                    {wordsToSearch.map((word, i: number) =>
                      Object.keys(wordsGroups).indexOf(word) === -1 ? (
                        <span className="word-search" key={i}>
                          {word} {"   "}
                          <span
                            role={"button"}
                            title={`Remover ${word}`}
                            onClick={() => removeFromWordsToSearch(word)}
                          >
                            <FontAwesomeIcon icon={faXmarkCircle} />
                          </span>
                        </span>
                      ) : (
                        <span
                          className="word-search-group"
                          style={{
                            backgroundColor: `${groupsColor[word]}`,
                          }}
                          key={i}
                        >
                          {word} {"   "}
                          <span
                            role={"button"}
                            title={Object.values(
                              wordsGroups[word as keyof {}]
                            ).join("\n")}
                          >
                            <FontAwesomeIcon icon={faBars} />
                          </span>
                          {"   "}
                          <span
                            role={"button"}
                            title={`Remover Grupo ${word}`}
                            onClick={() => removeFromWordsToSearch(word)}
                          >
                            <FontAwesomeIcon icon={faXmarkCircle} />
                          </span>
                        </span>
                      )
                    )}
                  </div>
                  <Form.Group>
                    <div className="d-flex mt-2">
                      <Col md={11}>
                        <input
                          className="form-control"
                          placeholder="Adicionar Palavra"
                          value={wordAdd}
                          onChange={(e) => setWordAdd(e.target.value)}
                        />
                      </Col>
                      <Col md={2}>
                        <Button
                          title="Adicionar Palavra"
                          onClick={() => {
                            if (
                              wordAdd != "" &&
                              wordsToSearch.indexOf(wordAdd) === -1
                            ) {
                              setWordsToSearch([...wordsToSearch, wordAdd]);
                              setWordAdd("");
                            } else {
                              toastSettings(
                                "Palavra inválida ou já adicionada",
                                "error"
                              );
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                      </Col>
                    </div>
                    <div className="mt-2 gap-1 container-words-group">
                      {wordsGroups != null &&
                        Object.keys(wordsGroups).map(
                          (group, i: number) =>
                            wordsToSearch.indexOf(group) === -1 && (
                              <span
                                className="word-search"
                                style={{
                                  backgroundColor: `${groupsColor[group]}`,
                                }}
                                key={i}
                              >
                                {group} {"   "}
                                <span
                                  role={"button"}
                                  title={Object.values(
                                    wordsGroups[group as keyof {}]
                                  ).join("\n")}
                                >
                                  <FontAwesomeIcon icon={faBars} />
                                </span>
                                {"   "}
                                <span
                                  role={"button"}
                                  title={`Adicionar Grupo ${group}`}
                                  onClick={() =>
                                    addFromGroupToWordsToSearch(group)
                                  }
                                >
                                  <FontAwesomeIcon icon={faPlusCircle} />
                                </span>
                              </span>
                            )
                        )}
                    </div>
                  </Form.Group>
                  <p className="text-danger">{errors.words?.message}</p>
                </Form.Group>
                <Form.Group>
                  <Button className="w-100" type="submit">
                    Processar
                  </Button>
                </Form.Group>
              </Form>
            </Col>
            <Col>
              {isLoading ? (
                <Loading />
              ) : (
                data.length > 0 && (
                  <>
                    {data.map((busca: any, i: number) => (
                      <Table responsive striped>
                        <thead>
                          <tr>
                            <th colSpan={4}>
                              {i + 1}. {busca?.source}
                            </th>
                          </tr>
                          <tr>
                            <th>Palavra</th>
                            <th>Localizadas</th>
                            <th>Total de Palavras</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {busca?.transcriptionData?.map(
                            (transcricoes: any, ii: number) => (
                              <tr key={ii}>
                                <td>{transcricoes?.palavra}</td>
                                <td>{transcricoes?.ocorrencias}</td>
                                <td>{transcricoes?.palavras}</td>
                                <td>
                                  <progress
                                    value={
                                      transcricoes?.ocorrencias /
                                      transcricoes?.palavras
                                    }
                                    max={100}
                                  >
                                    {transcricoes?.ocorrencias /
                                      transcricoes?.palavras}
                                  </progress>
                                </td>
                              </tr>
                            )
                          )}
                          <tr>
                            <td></td>
                            <td>
                              {busca?.transcriptionData
                                ?.map(
                                  (transcricoes: any) =>
                                    transcricoes?.ocorrencias
                                )
                                .reduce((a: number, b: number) => a + b)}
                            </td>
                            <td>{busca?.transcriptionData[0]?.palavras}</td>
                            <td>
                              <progress
                                value={
                                  busca?.transcriptionData
                                    ?.map(
                                      (transcricoes: any) =>
                                        transcricoes?.ocorrencias
                                    )
                                    .reduce((a: number, b: number) => a + b) /
                                  busca?.transcriptionData[0]?.palavras
                                }
                                max={100}
                              ></progress>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={4}>{busca?.category?.slice(1)}</td>
                          </tr>
                        </tbody>
                      </Table>
                    ))}
                  </>
                )
              )}
            </Col>
          </Row>
        </Container>
      </PanelMaster>

      <ModalLastTranscription
        show={showModalLastTranscription}
        onClose={setShowModalLastTranscription}
      />

      <ModalWorldGroup
        show={showModalWordGroup}
        onClose={setShowModalWordGroup}
      />
    </div>
  );
};

export default VideoTranscription;
