import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import "./ModalEditClient.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import Validation from "./Validation";
import { toastSettings } from "../../helpers/toastSettings";
import { Form } from "react-bootstrap";
import { useClient } from "../../providers/ClientProvider";
import Loading from "../Loading/Loading";
import Plans from "../../enums/Plans";

interface ModalEditClientProps {
  show: boolean;
  onClose?: any;
}

type FormValues = {
  active: boolean;
  plan: number;
  waitingTime: number;
  licencesEvolution: number;
  licencesEducation: number;
  licencesFull: number;
};

const ModalEditClient = ({ show, onClose }: ModalEditClientProps) => {
  const [modal, setModal] = useState<boolean>(false);

  const { selectedClient, updateClient } = useClient();

  useEffect(() => {
    if (show) {
      setModal(show);
    }
  }, [show]);

  useEffect(() => {
    if (selectedClient) {
      setValue("active", selectedClient?.ativo);
      setValue("plan", selectedClient?.plano);
      setValue("waitingTime", selectedClient?.tempoEsperaJornada);
      setValue("licencesEvolution", selectedClient?.licencasEvolution);
      setValue("licencesEducation", selectedClient?.licencasEducation);
      setValue("licencesFull", selectedClient?.licencasFull);
    }
  }, [selectedClient]);

  const toggle = () => setModal(!modal);
  const handleOnClose = () => onClose(false);

  const [isLoading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(Validation),
  });

  const handle = handleSubmit(
    async (data: { active: boolean; plan: number }) => {
      setLoading(true);
      const response = await updateClient(data);
      setLoading(false);

      if (response) {
        toggle();
        reset();
        return toastSettings(response.message, "success");
      }
    }
  );

  return (
    <Modal
      centered
      scrollable
      size="md"
      isOpen={modal}
      toggle={toggle}
      onClosed={handleOnClose}
      className="ModalEditClient"
    >
      <ModalHeader>Editar Cliente</ModalHeader>
      <ModalBody>
        <Form onSubmit={handle}>
          <Form.Group>
            <h5 className="py-1 text-center">{selectedClient?.nome}</h5>
          </Form.Group>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Form.Select defaultValue={"null"} {...register("active")}>
              <option key={0} value={0}>
                INATIVO
              </option>
              <option key={1} value={1}>
                ATIVO
              </option>
            </Form.Select>
            <p className="text-danger">{errors.active?.message}</p>
          </Form.Group>
          {/* <Form.Group>
            <Form.Label>Evolution</Form.Label>
            <Form.Select defaultValue={"null"} {...register("evolution")}>
              <option key={0} value={0}>
                INATIVO
              </option>
              <option key={1} value={1}>
                ATIVO
              </option>
            </Form.Select>
            <p className="text-danger">{errors.evolution?.message}</p>
          </Form.Group> */}
          <Form.Group>
            <Form.Label>Plano</Form.Label>
            <Form.Select defaultValue={"null"} {...register("plan")}>
              {Object.keys(Plans)
                .filter((key) => isNaN(Number(key)))
                .map((plan, i) => {
                  return (
                    <option key={i + 1} value={i + 1}>
                      {plan}
                    </option>
                  );
                })}
            </Form.Select>
            <p className="text-danger">{errors.plan?.message}</p>
          </Form.Group>
          <Form.Group>
            <Form.Label>Dias de espera para refazer Jornada</Form.Label>
            <Form.Control defaultValue={0} type="number" {...register("waitingTime")}></Form.Control>
            <p className="text-danger">{errors.waitingTime?.message}</p>
          </Form.Group>
          <Form.Group>
            <Form.Label>Licenças Evolution</Form.Label>
            <Form.Control defaultValue={0} type="number" {...register("licencesEvolution")}></Form.Control>
            <p className="text-danger">{errors.licencesEvolution?.message}</p>
          </Form.Group>
          <Form.Group>
            <Form.Label>Licenças Education</Form.Label>
            <Form.Control defaultValue={0} type="number" {...register("licencesEducation")}></Form.Control>
            <p className="text-danger">{errors.licencesEducation?.message}</p>
          </Form.Group>
          <Form.Group>
            <Form.Label>Licenças Full</Form.Label>
            <Form.Control defaultValue={0} type="number" {...register("licencesFull")}></Form.Control>
            <p className="text-danger">{errors.licencesFull?.message}</p>
          </Form.Group>
          {isLoading && <Loading />}
          <button
            disabled={isLoading ?? false}
            type="submit"
            className="mt-3 btn btn-primary"
          >
            ATUALIZAR
          </button>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="button" onClick={toggle}>
          <span>Fechar</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalEditClient;
