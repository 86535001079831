import { FC, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  Accordion,
  Button,
  Card,
  ModalBody,
  ModalHeader,
  ModalTitle,
  Table,
} from "react-bootstrap";
import { Modal } from "reactstrap";
import Loading from "../Loading/Loading";
import Api from "../../services/Api";
import "./ModalWorldGroup.scss";
import TranscriptionService from "../../services/TranscriptionService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faRefresh, faTrash } from "@fortawesome/free-solid-svg-icons";
import ModalAddWordOrGroup from "../ModalAddWordOrGroup/ModalAddWordOrGroup";
import ModalConfirmation from "../ModalConfirmation/ModalConfirmation";
import { toastSettings } from "../../helpers/toastSettings";

const ModalLastTranscription: FC<any> = ({ show, onClose }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [showModalAddWordOrGroup, setShowModalAddWordOrGroup] =
    useState<boolean>(false);
  const [addWordOrGroup, setAddWordOrGroup] = useState<string>("");
  const [idGroup, setIdGroup] = useState<Number>(0);

  const [modalConfirmationDeleteGroup, setModalConfirmationDeleteGroup] =
    useState<boolean>(false);
  const [modalConfirmationDeleteWord, setModalConfirmationDeleteWord] =
    useState<boolean>(false);

  const [selectedGroupId, setSelectedGroupId] = useState<Number>(0);
  const [selectedWordId, setSelectedWordId] = useState<Number>(0);

  const toggle = () => setModal(!modal);
  const handleOnClose = () => onClose(false);
  const [wordsGroups, setWordsGroups] = useState<[]>([]);

  useEffect(() => {
    if (show) {
      setModal(show);
    }
  }, [show, isLoading]);

  useEffect(() => {
    handleGroupsAndWords();
  }, []);

  const handleGroupsAndWords = async () => {
    setLoading(true);
    const data = await TranscriptionService.getGroupsAndWords();
    setLoading(false);
    setWordsGroups(data);
  };

  const deleteGroup = async () => {
    const data = await TranscriptionService.deleteGroup(selectedGroupId);
    if (data) toastSettings("Grupo removido com sucesso!", "success");
    setSelectedGroupId(0);
  };

  const deleteWord = async () => {
    const data = await TranscriptionService.deleteWord(selectedWordId);
    if (data) toastSettings("Palavra removida com sucesso!", "success");
    setSelectedWordId(0);
  };

  return (
    <>
      <Modal
        size="lg"
        isOpen={modal}
        toggle={toggle}
        onClosed={handleOnClose}
        className="ModalWorldGroup"
      >
        <ModalHeader>Grupo de Palavras da Curadoria</ModalHeader>
        <ModalBody>
          <div className="d-flex gap-2">
            <Button
              title="Adicionar Grupo"
              onClick={() => {
                setAddWordOrGroup("Grupo");
                setShowModalAddWordOrGroup(true);
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
            <Button
              title="Recarregar Lista"
              onClick={() => {
                handleGroupsAndWords();
              }}
            >
              <FontAwesomeIcon icon={faRefresh} />
            </Button>
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <Accordion>
              {wordsGroups.map((group: any, i: number) => {
                return (
                  <Card className="mt-2" key={i}>
                    <Card.Header id={`#heading${i}`}>
                      <div className="d-flex justify-content-between">
                        <div>
                          <button
                            className="btn"
                            data-toggle="collapse"
                            data-target={`#collapse${i}`}
                            aria-expanded="true"
                            aria-controls={`collapse${i}`}
                          >
                            {group?.descricao}
                          </button>
                        </div>
                        <div className="d-flex gap-2">
                          <Button
                            title="Adicionar Palavra"
                            onClick={() => {
                              setIdGroup(group?.id);
                              setAddWordOrGroup("Palavra");
                              setShowModalAddWordOrGroup(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                          <Button
                            title="Remover Grupo"
                            onClick={() => {
                              setSelectedGroupId(group?.id);
                              setModalConfirmationDeleteGroup(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </div>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      {group?.palavra?.map((palavra: any, ii: number) => {
                        return (
                          <div
                            key={ii}
                            className="d-flex justify-content-between"
                          >
                            <td>{palavra?.descricao}</td>
                            <td>
                              <Button
                                title="Remover Palavra"
                                onClick={() => {
                                  setSelectedWordId(palavra?.id);
                                  setModalConfirmationDeleteWord(true);
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </td>
                          </div>
                        );
                      })}
                    </Card.Body>
                  </Card>
                );
              })}
            </Accordion>
          )}
        </ModalBody>
      </Modal>
      <ModalAddWordOrGroup
        show={showModalAddWordOrGroup}
        onClose={setShowModalAddWordOrGroup}
        type={addWordOrGroup}
        idGroup={idGroup}
      />
      <ModalConfirmation
        show={modalConfirmationDeleteGroup}
        onClose={setModalConfirmationDeleteGroup}
        message={
          "Você tem certeza que deseja remover este grupo e todas as palavras associadas a ele?"
        }
        onConfirm={deleteGroup}
      />
      <ModalConfirmation
        show={modalConfirmationDeleteWord}
        onClose={setModalConfirmationDeleteWord}
        message={"Você tem certeza que deseja remover esta palavra?"}
        onConfirm={deleteWord}
      />
    </>
  );
};

export default ModalLastTranscription;
