import { FC, useEffect, useState } from "react";
import {
  Button,
  Form,
  FormLabel,
  ModalBody,
  ModalHeader,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Modal } from "reactstrap";
import { toastSettings } from "../../helpers/toastSettings";
import TranscriptionService from "../../services/TranscriptionService";
import Loading from "../Loading/Loading";
import "./ModalAddWordOrGroup.scss";

type FormValues = {
  descricao: String;
  cor?: String;
  idGrupoPalavras?: Number;
};

const ModalAddWordOrGroup: FC<any> = ({ show, onClose, type, idGroup }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);

  const toggle = () => setModal(!modal);
  const handleOnClose = () => onClose(false);

  useEffect(() => {
    if (show) {
      setModal(show);
    }
  }, [show, isLoading]);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
  } = useForm<FormValues>({});

  const handle = handleSubmit(async (formValues: FormValues) => {
    setLoading(true);
    formValues.idGrupoPalavras = idGroup;
    let data;
    if (type == "Grupo") data = await TranscriptionService.addGroup(formValues);
    else data = await TranscriptionService.addWord(formValues);

    if (data?.id != undefined) {
      toastSettings("Salvo com sucesso", "success");
      reset();
    }

    setLoading(false);
  });

  return (
    <Modal
      size="mds"
      isOpen={modal}
      toggle={toggle}
      onClosed={handleOnClose}
      className="ModalWorldGroup"
    >
      <ModalHeader>Adicionar {type}</ModalHeader>
      <ModalBody>
        {isLoading && <Loading />}

        <Form onSubmit={handle}>
          <Form.Group>
            <Form.Label>Descrição</Form.Label>
            <Form.Control
              defaultValue={""}
              required
              {...register("descricao")}
            ></Form.Control>
            <p className="text-danger">{errors.descricao?.message}</p>
          </Form.Group>

          {type == "Grupo" && (
            <Form.Group>
              <Form.Label>Cor</Form.Label>
              <Form.Control
                type="color"
                className="w-100"
                defaultValue={""}
                {...register("cor")}
              ></Form.Control>
              <p className="text-danger">{errors.cor?.message}</p>
            </Form.Group>
          )}
          <Form.Group>
            <Button className="w-100" type="submit">
              Salvar
            </Button>
          </Form.Group>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalAddWordOrGroup;
