import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import RootRoute from "./routes/RootRoute";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "./providers/AuthProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter basename={`${process.env.REACT_APP_BASE}`}>
    <AuthProvider>
      <RootRoute />
      <ToastContainer />
    </AuthProvider>
  </BrowserRouter>
);
