import { FC, useEffect } from "react";
import { Container, Dropdown, DropdownButton, Navbar } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useAuth } from "../../providers/AuthProvider";
import "./Header.scss";

const Header: FC = () => {
  const { user, logout } = useAuth();

  return (
    <div className="Navbar">
      <Navbar>
        <Container>
          <Navbar.Brand href="/">
            <img src={"./assets/img/logo_2023.png"} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <DropdownButton id="dropdown-basic-button" title={user?.nome}>
                <Dropdown.Item href="/">Início</Dropdown.Item>
                <Dropdown.Item href="/history">Histórico</Dropdown.Item>
                <Dropdown.Item href="/managers">Gestores</Dropdown.Item>
                <Dropdown.Item href="/clients">Clientes</Dropdown.Item>
                <Dropdown.Item href="/curadoria">
                  Curadoria
                </Dropdown.Item>
                <Dropdown.Item href="#" onClick={logout}>
                  Sair
                </Dropdown.Item>
              </DropdownButton>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
