import axios from "axios";
import Cookies from "js-cookie";
import { toastSettings } from "../helpers/toastSettings";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
};

const responseErrorHandler = async (error: any) => {
  let pathname = window.location.pathname;

  if (error.response.status === 401) {
    if (pathname !== "/login") {
      localStorage?.clear();
      Cookies.remove("api_token");
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
  }

  if (error.response.data.message) {
    toastSettings(error.response.data.message, "error");
  }

  return Promise.reject(error);
};

const Api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: headers,
});

Api.interceptors.request.use(async (request) => {
  const token = (await Cookies.get("api_token")) || "";

  if (token && request.headers) {
    request.headers["api-token"] = `${token}`;
  }

  return request;
});

Api.interceptors.response.use(
  async (request) => {
    return request;
  },
  (error) => responseErrorHandler(error)
);

export default Api;
