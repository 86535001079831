import * as yup from "yup";

const Validation = yup.object().shape({
  active: yup.boolean().required("O campo ativo é obrigatório"),
  plan: yup.number().required("O campo plano é obrigatório"),
  waitingTime: yup.number().required("O campo plano é obrigatório"),
  licencesEvolution: yup.number().required("O campo Licenças Evolution é obrigatório"),
  licencesEducation: yup.number().required("O campo Licenças Education é obrigatório"),
  licencesFull: yup.number().required("O campo Licenças Full é obrigatório"),
});

export default Validation;
