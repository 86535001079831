const renders = {
  renderBgIconHex: (item: string) => {
    if (item === "Dinheiro") {
      return "hsla(82, 72%, 39%, 1)";
    } else if (item === "Saúde") {
      return "hsl(199, 55%, 59%)";
    } else if (item === "Conhecimento") {
      return "hsl(26, 67%, 59%)";
    } else if (item === "Tempo") {
      return "hsl(273, 38%, 35%)";
    } else if (item === "Emoções") {
      return "hsl(356, 57%, 50%)";
    }
  },
  renderIcon: (id: number, name: string) => {
    if (id === 1 || name === "Dinheiro") {
      return (
        <img src="/assets/img/icons/resources/icon_money.svg" alt="Dinheiro" />
      );
    } else if (id === 2 || name === "Saúde") {
      return (
        <img src="/assets/img/icons/resources/icon_health.svg" alt="Saúde" />
      );
    } else if (id === 3 || name === "Conhecimento") {
      return (
        <img
          src="/assets/img/icons/resources/icon_knowledge.svg"
          alt="Conhecimento"
        />
      );
    } else if (id === 4 || name === "Tempo") {
      return (
        <img src="/assets/img/icons/resources/icon_time.svg" alt="Tempo" />
      );
    } else if (id === 5 || name === "Emoções") {
      return (
        <img
          src="/assets/img/icons/resources/icon_emotions.svg"
          alt="Emoções"
        />
      );
    }
  },
  renderBgIcon: (item: string) => {
    if (item === "Dinheiro") {
      return "money";
    } else if (item === "Saúde") {
      return "health";
    } else if (item === "Conhecimento") {
      return "knowledge";
    } else if (item === "Tempo") {
      return "time";
    } else if (item === "Emoções") {
      return "emotions";
    }
  },
};

export default renders;
