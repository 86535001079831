import React, { useEffect, useState } from "react";
import { User } from "../models/User";
import Cookies from "js-cookie";
import AuthService from "../services/AuthService";

export const AuthContext = React.createContext<User | any>({});

export const AuthProvider = (props: any) => {
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const disposer = () => {
      const userStorage = localStorage.getItem("@uelf:adm");
      setUser(!!userStorage ? JSON.parse(userStorage) : null);
    };
    disposer();
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem("@uelf:adm", JSON.stringify(user));
    }
  }, [user]);

  const login = async (data: { email: string; password: string }) => {
    const _Response = await AuthService.login(data);

    if (!_Response) {
      return false;
    }

    setUser({ ...user, ..._Response?.usuario });

    Cookies.set("api_token", _Response?.api_token, {
      path: "/",
      expires: 1,
    });

    return true;
  };

  const logout = async () => {
    const logout = AuthService.logout();

    return logout;
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        login,
        error,
        setError,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
