import { FC, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Header from "../../components/Header/Header";
import ManagersTable from "../../components/ManagersTable/ManagersTable";
import ModalAddManager from "../../components/ModalAddManager/ModalAddManager";
import PanelMaster from "../../components/PanelMaster/PanelMaster";
import "./Managers.scss";

type ManagersProps = {};

const Managers: FC<ManagersProps> = () => {
  const [filteredText, setFilteredText] = useState<string>("");
  const [showModalAddManager, setShowModalAddManager] =
    useState<boolean>(false);

  return (
    <div className="Managers">
      <Header />
      <PanelMaster title="Gestores">
        <Container>
          <Row className="m-0 p-0">
            <Col xs={12} md={12}>
              <div className="d-flex justify-content-between">
                <Col xs={9} md={4}>
                  <input
                    type="text"
                    className="search w-100"
                    placeholder="Procurar por nome ou cliente"
                    onChange={(e) => setFilteredText(e.target.value)}
                  />
                </Col>
                <Col xs={9} md={4}>
                  <Button
                    className="ml-auto"
                    onClick={() => setShowModalAddManager(true)}
                  >
                    ADICIONAR
                  </Button>
                </Col>
              </div>
            </Col>
          </Row>
          <div className="clearfix mt-5"></div>
          <ManagersTable filter={filteredText} />
        </Container>
      </PanelMaster>
      <ModalAddManager
        show={showModalAddManager}
        onClose={setShowModalAddManager}
      />
    </div>
  );
};

export default Managers;
