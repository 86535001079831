import { FC, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import Header from "../../components/Header/Header";
import PanelMaster from "../../components/PanelMaster/PanelMaster";
import UsersTable from "../../components/UsersTable/UsersTable";
import "./History.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import ModalFilter from "../../components/ModalFilter/ModalFilter";

type HistoryProps = {};

const History: FC<HistoryProps> = () => {
  const [filteredText, setFilteredText] = useState<string>("");
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const [selectFilterSetor, setSelectFilterSetor] = useState<string>("");
  const [selectFilterRecurso, setSelectFilterRecurso] = useState<string>("");
  const [selectFilterDtConclusao, setSelectFilterDtConclusao] = useState<string>("");
  const [selectFilterCliente, setSelectFilterCliente] = useState<string>("");

  return (
    <div className="History">
      <Header />
      <PanelMaster title="Histórico">
        <Container>
          <Row className="m-0 p-0">
            <Col xs={12} md={10}>
              <div className="d-flex">
                <Col xs={9} md={4}>
                  <input
                    type="text"
                    className="search w-100"
                    placeholder="Procurar por nome"
                    onChange={(e) => setFilteredText(e.target.value)}
                  />
                </Col>
              </div>
            </Col>
            <Col xs={12} md={2} className="d-flex justify-content-end">
              <button
                className="btn-secondary btn filter"
                onClick={() => {
                  setShowFilter(true);
                }}
              >
                <FontAwesomeIcon icon={faFilter} /> FILTROS
              </button>
            </Col>
          </Row>
          <div className="clearfix mt-5"></div>
          <UsersTable filter={filteredText} selectFilterSetor={selectFilterSetor} selectFilterRecurso={selectFilterRecurso} selectFilterDtConclusao={selectFilterDtConclusao} selectFilterCliente={selectFilterCliente} />
        </Container>
      </PanelMaster>
      <ModalFilter
        show={showFilter}
        onClose={setShowFilter}
        setSelectFilterDtConclusao={setSelectFilterDtConclusao}
        setSelectFilterSetor={setSelectFilterSetor}
        setSelectFilterRecurso={setSelectFilterRecurso}
        setSelectCliente={setSelectFilterCliente}
      />
    </div>
  );
};

export default History;
